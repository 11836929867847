<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" id="quotaReviewList">
    <el-row :gutter="20">
      <el-col :span="1"
        ><el-button type="primary" icon="el-icon-timer"
          >添加客户</el-button
        ></el-col
      >
    </el-row>
    <div id="form">
      <el-form
        :model="addForm.legalInfo"
        :rules="legalInfoRules"
        ref="legalInfo"
        :inline="true"
      >
        <el-row>
          <el-col :span="20">
            <el-descriptions
              class="margin-top"
              title="管理员信息"
              :column="2"
              size=""
              border
            >
              <el-descriptions-item
                ><template slot="label">管理员姓名：</template>
                <el-form-item prop="name">
                  <el-input
                    placeholder="请输入管理员姓名"
                    v-model="addForm.legalInfo.name"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">管理员身份证号码：</template>
                <el-form-item prop="idno">
                  <el-input
                    placeholder="请输入管理员身份证号码"
                    v-model="addForm.legalInfo.idno"
                    :disabled="type == 1"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              
              <el-descriptions-item
                ><template slot="label">预留手机号码：</template>
                <el-form-item prop="mobile">
                  <el-input
                    placeholder="请输入手机号"
                    v-model="addForm.legalInfo.mobile"
                    maxlength="11"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              
              <el-descriptions-item />
            </el-descriptions>
          </el-col>
        </el-row>
      </el-form>

      <el-form
        :model="addForm.enterpriseInfo"
        :rules="enterpriseInfoRules"
        ref="enterpriseInfo"
        :inline="true"
      >
        <el-row :gutter="20">
          <el-col :span="20">
            <el-descriptions
              class="margin-top"
              title="企业信息"
              :column="2"
              size=""
              border
            >
              <el-descriptions-item
                ><template slot="label">企业名称：</template>
                <el-form-item prop="name">
                  <el-input
                    placeholder="请输入企业名称"
                    v-model="addForm.enterpriseInfo.name"
                  />
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label"
                  >社会统一信用代码：</template
                >
                <el-form-item prop="code">
                  <el-input
                    placeholder="请输入代码"
                    v-model="addForm.enterpriseInfo.code"
                    :disabled="type == 1"
                  />
                </el-form-item>
              </el-descriptions-item>
              
              <!-- <el-descriptions-item
                ><template slot="label">企业注册地址：</template>
                <el-form-item prop="areaOptions">
                  <el-cascader
                    :options="areaOptions"
                    clearable
                    :props="areaProps"
                    placeholder="请选择注册地址"
                  ></el-cascader>
                </el-form-item>
              </el-descriptions-item> -->
              <el-descriptions-item
                ><template slot="label">税务登记号：</template>
                <el-form-item prop="taxRegistrationNo">
                  <el-input
                    placeholder="请输入税务登记号"
                    v-model="addForm.enterpriseInfo.taxRegistrationNo"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">企业注册详细地址：</template>
                <el-form-item prop="address">
                  <el-input
                    placeholder="请输入详细地址"
                    v-model="addForm.enterpriseInfo.address"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">营业执照：</template>
                <el-form-item prop="businessLicenseUrl">
                  <el-row type="flex">
                    <el-upload
                    class="upload-demo"
                    :file-list="businessLicenseUrlList"
                    :action="uploadUrl"
                    :on-success="businessLicenseUrlSuccess"
                    :on-remove="businessLicenseUrlRemove"
                    :data="uploadData"
                    :multiple="false"
                    :limit="1"
                  >
                    <el-button size="mini">点击上传</el-button>
                  </el-upload>
                    <a v-if="businessLicenseUrl" :href="businessLicenseUrl" style="color:#66b1ff;text-decoration: none;">点击下载</a>
                  </el-row>
    
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item>
              </el-descriptions-item>
              
            </el-descriptions>
          </el-col>
        </el-row>
      </el-form>

      <el-form
        :model="addForm"
        :rules="servicrRate"
        ref="addForm"
        :inline="true"
      >
        <el-row :gutter="20">
          <el-col :span="20">
            <el-descriptions
              class="margin-top"
              title="发薪单位"
              :column="2"
              size=""
              border
            >
              <el-descriptions-item
                ><template slot="label">开户银行：</template>
                <el-form-item prop="bankName">
                  <el-input
                    placeholder="请输入开户银行"
                    v-model="addForm.bankName"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">开户账号：</template>
                <el-form-item prop="bankcard">
                  <el-input
                    placeholder="请输入开户账号"
                    v-model="addForm.bankcard"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">开户联行号：</template>
                <el-form-item prop="brdnbr">
                  <el-input
                    placeholder="请输入开户联行号"
                    v-model="addForm.brdnbr"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">公司标识：</template>
                <el-select v-model="addForm.tenantIden" placeholder="请选择" prop="tenantIden">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <!-- <template slot="label">企业注册地址：</template>
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select> -->
              </el-descriptions-item>
              
              
              <!-- <el-descriptions-item
                ><template slot="label"></template>
              </el-descriptions-item> -->
            </el-descriptions>
          </el-col>
        </el-row>
      </el-form>


      <el-form
        :model="addForm.accountInfo"
        :rules="accountInfoRules"
        ref="accountInfo"
        :inline="true"
      >
        <el-row :gutter="20">
          <el-col :span="20">
            <el-descriptions
              class="margin-top"
              title="账户信息"
              :column="2"
              size=""
              border
            >
              <el-descriptions-item
                ><template slot="label">账号：</template>
                <el-form-item prop="account">
                  <el-input
                    :disabled="type == 1"
                    placeholder="请输入账号"
                    v-model="addForm.accountInfo.account"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item v-if="type != 1"
                ><template slot="label">密码：</template>

                <el-form-item
                  prop="salted"
                  :rules="[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                  ]"
                >
                  <div id="password">
                    <el-input
                      placeholder="请输入密码"
                      v-model="addForm.accountInfo.salted"
                      show-password
                    ></el-input>
                  </div>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">手机：</template>
                <el-form-item prop="mobile">
                  <el-input
                    :disabled="type == 1"
                    placeholder="请输入手机"
                    v-model="addForm.accountInfo.mobile"
                    maxlength="11"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">邮箱：</template>
                <el-form-item prop="email">
                  <el-input
                    :disabled="type == 1"
                    placeholder="请输入邮箱"
                    v-model="addForm.accountInfo.email"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label">身份证：</template>
                <el-form-item prop="idno">
                  <el-input
                    placeholder="请输入身份证"
                    v-model="addForm.accountInfo.idno"
                    :disabled="type == 1"
                  ></el-input>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                ><template slot="label"></template>
              </el-descriptions-item>
            </el-descriptions>
          </el-col>
        </el-row>
      </el-form>

      <el-form
        :model="addForm"
        :rules="servicrRate"
        ref="addForm"
        :inline="true"
      >
        <el-row :gutter="20">
          <el-col :span="20">
            <el-descriptions
              class="margin-top"
              title="支付信息"
              :column="2"
              size=""
              border
            >
              <el-descriptions-item
                ><template slot="label">支付密码：</template>
                <el-form-item prop="payPwd">
                  <div id="password">
                  <el-input
                    type="number"
                    show-password
                    placeholder="请输入支付密码："
                    v-model.number="addForm.payPwd"
                    maxlength="6"
                  ></el-input>
                  </div>
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item>
                
              </el-descriptions-item>
              
            </el-descriptions>
          </el-col>
        </el-row>
      </el-form>

    </div>
    <div style="height: 30px;"></div>
    <el-row :gutter="20">
        <el-col :span="6" :offset="3"><div><el-button @click="$router.push(`/tenantList`)">取消</el-button></div></el-col>
        <el-col :span="6"><div><el-button type="primary" @click="addOrUpdateTenantAndSetting">{{submitButtonValue}}</el-button></div></el-col>
    </el-row>
  </div>
</template>
<script>
// import setting from "./setting.vue";

import validateUtils from "@/util/validateUtils";

export default {
  data() {
    return {
      options: [{
          value: '0',
          label: '主体公司'
        }, {
          value: '1',
          label: '客户公司'
        }],
      fullscreenLoading: false,
      type: null,
      echoForm: {
        teanant: null,
        creditCode: null,
        user: null,
      },
      tenantInfo:'',
      
      submitButtonValue: "",
      uploadUrl: process.env.VUE_APP_UPLOAD_URL+'/oss/uploadFile',
      uploadData: {
        type: process.env.VUE_APP_UPLOAD_TYPE,
      },
      imageUrl: "",
      // 地址选择值 id
      areaOptions: null,

      areaProps: {
        label: "area",
        value: "id",
      },
      // 开户行
      bankOptions: [],
      bankBranchOptions: [],
      branchLoading: true,
      // 法人信息验证
      legalInfoRules: {
        name: [{ required: true, message: "请输入管理员姓名", trigger: "blur" }],
        idno: [
          { required: true, message: "请输入管理员身份证号码", trigger: "blur" },
          { validator: this.isIdno, trigger: "blur" },
        ],
        bankcard: [
          { required: true, message: "请输入管理员银行卡号", trigger: "blur" },
        ],
        //   bankNo: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        bankName: [
          { required: true, message: "请选择开户行", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: this.isMobile, trigger: "blur" },
        ],
        
        reverseIdcard: [
          {
            required: true,
            message: "请上传身份证背面",
            trigger: ["blur", "change"],
          },
        ],
        reverseIdcard: [
          {
            required: true,
            message: "请上传身份证背面",
            trigger: ["blur", "change"],
          },
        ],
      },
      frontIdcardList: [],
      reverseIdcardList: [],
      businessLicenseUrlList: [],
      // 企业信息验证
      enterpriseInfoRules: {
        name: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入代码", trigger: "blur" }],
        businessLicenseRegistrationNo: [
          { required: true, message: "请输入营业执照注册号", trigger: "blur" },
        ],
        taxRegistrationNo: [
          { required: true, message: "请输入税务登记号", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        // bankNo: [{ required: true, message: '请输入法人姓名', trigger: 'blur' }],
        bankName: [
          { required: true, message: "请选择开户行", trigger: "change" },
        ],
        businessLicenseUrl: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
        branchName: [
          { required: true, message: "请选择开户行支行", trigger: "change" },
        ],
      },
      // 账号信息校验
      accountInfoRules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        // salted: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        mobile: [
          { required: true, message: "请输入手机", trigger: "blur" },
          { validator: this.isMobile, trigger: "blur" },
        ],
        idno: [
          { required: true, message: "请输入身份证", trigger: "blur" },
          { validator: this.isIdno, trigger: "blur" },
        ],
        // email: [
        //   { required: true, message: "请输入邮箱", trigger: "blur" },
        //   // { validator: this.isEmail, trigger: "blur" },
        // ],
      },
      servicrRate:{
        bankName: [{ required: true, message: "请输入开户银行", trigger: "blur" }],
        bankcard: [{ required: true, message: "请输入开户账号", trigger: "blur" }],
        brdnbr: [{ required: true, message: "请输入开户联行号", trigger: "blur" }],
        payPwd: [{ required: true, message: "请输入支付密码", trigger: "blur" }],
        tenantIden: [{ required: true, message: "请选择公司标识", trigger: "blur" }],
      },
      // 修改收集id
      updateIds: {
        corpRiskId: null,
        personRiskId: null,
        tenant: null,
        user: null,
      },
      // 搜集的form值
      addForm: {
        // 法人信息
        legalInfo: {
          name: "",
          idno: undefined,
          bankcard: undefined,
          bankNo: undefined,
          bankName: undefined,
          mobile: undefined,
          frontIdcard: undefined,
          reverseIdcard: undefined,
          
        },
        enterpriseInfo: {
          name: undefined,
          code: undefined,
          businessLicenseRegistrationNo: undefined,
          taxRegistrationNo: undefined,
          address: undefined,
          bankcard: undefined,
          bankNo: undefined,
          bankName: undefined,
          businessLicenseUrl: undefined,
          branchName: undefined,
          branchNo: undefined,
        },
        accountInfo: {
          account: undefined,
          salted: undefined,
          mobile: undefined,
          idno: undefined,
          email: undefined,
        },
        
        bankName: undefined,
        bankcard: undefined,
        brdnbr:undefined,
        tenantIden: undefined,
        payPwd:undefined,
        vatRate: 6.0,
        additionalRate:undefined,
        serviceRate: undefined,
        deductibleRate: undefined,
        personalIncomeRate: undefined,
      },
    };
  },
  // 实例创建完成后调用
  async created() {
    this.type = this.$route.params.type;
    // type = 0 添加 1 修改
    if (this.type == "1") {
      this.fullscreenLoading = true;
      this.submitButtonValue = "修改";
      this.echoForm.user = this.$route.query.id;
      this.echoForm.teanant = this.$route.query.tid;
      this.echoForm.creditCode = this.$route.query.code;
      this.loadtenantData();
      this.loadData();
      
      
    } else {
      this.submitButtonValue = "添加";
    }
    // this.finTenantInfo();
    let flag = this;
    // 加载地址级联
    await this.api.platformApi.getTreeDicArea({}).then((res) => {
      res = res.data;
      if (res.code == 200) {
        flag.areaOptions = res.data;
      } else {
        this.$message.error("级联地址加载失败，刷新重试！");
      }
    });
  },
  methods: {
    isBankNameMsg(val) {
      // addForm.enterpriseInfo.branchName
      if (this.isEntity(this.addForm.enterpriseInfo.bankName)) {
        this.$message.error("请先悬着开户行");
      }
    },
    // 修改时回显对应的值
    async loadData() {
      await this.api.platformApi.updateTenantFind(this.echoForm).then((res) => {
        res = res.data;
        this.fullscreenLoading = false;
        if ((res.code == 200)) {
          console.log("---testssss----",res.data);
          this.addForm.legalInfo = res.data.legalInfo;
          this.addForm.enterpriseInfo = res.data.enterpriseInfo;
          this.addForm.accountInfo = res.data.accountInfo;
          //
          this.updateIds.corpRiskId = res.data.corpRiskId;
          this.updateIds.personRiskId = res.data.personRiskId;
          this.updateIds.tenant = res.data.tenant;
          this.updateIds.user = res.data.user;
          // this.frontIdcardList=[];
          // let frontIdcardUrl = res.data.legalInfo.frontIdcard;
          // this.frontIdcardList.push({name:this.splitPop(frontIdcardUrl,'/'),url:frontIdcardUrl});
          // this.reverseIdcardList=[];
          // let reverseIdcardUrl = res.data.legalInfo.reverseIdcard;
          // this.reverseIdcardList.push({name:this.splitPop(reverseIdcardUrl,'/'),url:reverseIdcardUrl});
          this.businessLicenseUrlList=[];
          let businessLicenseUrl = res.data.enterpriseInfo.businessLicenseUrl;
          this.businessLicenseUrl = businessLicenseUrl
          if(businessLicenseUrl){
            this.businessLicenseUrlList.push({name:this.splitPop(businessLicenseUrl,'/'),url:businessLicenseUrl});
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    async loadtenantData() {
      // console.log("------------------------",this.echoForm.teanant)
      await this.api.salaryApi.tenantIds({tenant: this.echoForm.teanant}).then((res) => {
        res = res.data;
        console.log("-----tenantInfo",res)
        this.fullscreenLoading = false;
        if ((res != null)) {
          this.addForm.bankName = res.bankName
          this.addForm.bankcard = res.bankcard
          this.addForm.brdnbr = res.brdnbr
          this.addForm.tenantIden = res.tenantIden
          this.addForm.payPwd = res.payPwd
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    splitPop(str,symbol){
      if(str == null || str == undefined || str === '' ){
        return "";
      }
      if(symbol == null || symbol == undefined || symbol === '' ){
        return str;
      }
      let arr = str.split(symbol);
      return arr[arr.length-1];
    },
   
    // 提交表单
    async addOrUpdateTenantAndSetting() {
      // 法人验证
      this.$refs["legalInfo"].validate((valid) => {
        if (valid) {
          // 企业验证
          this.$refs["enterpriseInfo"].validate((valid) => {
            if (valid) {
              // 账号信息验证
              this.$refs["accountInfo"].validate((valid) => {
                if (valid) {
                  this.$refs["addForm"].validate((valid) => {
                    if (valid) {
                      this.fullscreenLoading = true;
                      if (this.type == "0") {
                        this.api.platformApi
                          .addTenant(this.addForm)
                          .then((res) => {
                            res = res.data;
                            if (res.code == 200) {
                              // 添加成功！
                              this.$router.push(`/tenantList`);
                            } else {
                              // 添加失败！
                              this.$message.error(res.msg);
                            }
                            this.fullscreenLoading = false;
                          });
                      } else if (this.type == "1") {
                        this.addForm["corpRiskId"] = this.updateIds.corpRiskId;
                        this.addForm["personRiskId"] = this.updateIds.personRiskId;
                        this.addForm["tenant"] = this.updateIds.tenant;
                        this.addForm["user"] = this.updateIds.user;
                        this.api.platformApi
                          .updateTenant(this.addForm)
                          .then((res) => {
                            res = res.data;
                            if (res.code == 200) {
                              // 添加成功！
                              this.$router.push(`/tenantList`);
                            } else {
                              // 添加失败！
                              this.$message.error(res.msg);
                            }
                            this.fullscreenLoading = false;
                          });
                      }
                      } else {
                console.log("addForm!!");
                return false;
              }
              });
              } else {
                console.log("accountInfo!!");
                return false;
              }
              });
            } else {
              console.log("enterpriseInfo!!");
              return false;
            }
          });
        } else {
          console.log("legalInfo!!");
          return false;
        }
      });
    },
    businessLicenseUrlSuccess(res, file) {
      if (res.code == 200) {
        this.addForm.enterpriseInfo.businessLicenseUrl = res.data;
        this.$refs.enterpriseInfo.validateField("businessLicenseUrl");
        this.$message({
          message: "营业执照上传成功！",
          type: "success",
        });
        console.log('addOrUpdateTenant',this.businessLicenseUrlList)
      }
    },
    businessLicenseUrlRemove(file, fileList) {
      this.addForm.enterpriseInfo.businessLicenseUrl = undefined;
    },
    // 重置form 表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    isIdno(rule, value, callback) {
      if (validateUtils.isIdno(value)) {
        callback();
      } else {
        callback(new Error("身份证不合法！"));
      }
    },
    isEmail(rule, value, callback) {
      if (validateUtils.isEmail(value)) {
        callback();
      } else {
        callback(new Error("邮箱不合法！"));
      }
    },
    isMobile(rule, value, callback) {
      if (validateUtils.isMobile(value)) {
        callback();
      } else {
        callback(new Error("手机号不合法！"));
      }
    },
    isEntity(val) {
      if (val == null || val == undefined || val == "") {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {},
};
</script>

<style scoped>
>>> .el-descriptions-item__cell {
  padding: 2px 5px !important;
}
>>> .el-descriptions-item__label {
  width: 250px;
  text-align: end !important;
}

/*  */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
>>> .avatar-uploader {
  display: inline-block;
  margin-left: 10px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 18px;
  color: #8c939d;
  width: 130px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.avatar {
  width: 130px;
  height: 30px;
  display: block;
}

#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}

>>> .el-input-number {
  padding: 0px;
  /* text-align: end !important; */
}
>>> .el-input__inner {
  text-align: initial !important;
}
/* ------------------------------- */
>>> .el-descriptions__header {
  margin: 20px 0px 0px 0px;
  color: #1d90ff;
}

>>> #form .el-form-item {
  margin-bottom: 0px;
  display: inline !important;
  margin-right: 0px !important;
  vertical-align: top !important;
}
>>> #form .el-form-item__content {
  /* display: inline !important; */
  display: block;
}
>>> #form .el-input {
  width: 50%;
}
>>> #form .el-form-item__error {
  top: 0%;
  /* left: 100%;
    width: 100%; */
  left: 51%;
  width: 50%;
  line-height: inherit;
  padding-top: initial;
}
>>> #form .el-input--suffix {
  width: 100%;
}
>>> #password .el-input--suffix {
  width: 50%;
}
>>> #form .el-select {
  /* display: initial; */
  width: 50%;
}
.upload-demo {
  width: 70%;
}
>>> #form .el-upload-list {
  float: right;
  width: calc(100% - 85px);
}
>>> #form .el-descriptions-item__content {
  width: 35%;
}

>>> #form .el-cascader {
  width: 50%;
}
/* >>> #form .el-upload-list__item-thumbnail{
    width: 0px;
    height: 0px;
}
>>> #form .el-upload-list__item{
    margin-top: initial;
    padding: initial;
    height: initial;
} */
</style>


